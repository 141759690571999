<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="120px"
    class="demo-ruleForm"
    status-icon
  >
    <el-row>
      <el-col :span="7">
        <el-form-item label="缴费名称" prop="name">
          <el-input v-model="ruleForm.name" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="请选择层次" prop="level_id">
      <el-select v-model="ruleForm.level_id" placeholder="请选择层次">
        <el-option
          v-for="item in hierarchicalData"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </el-form-item>

    <el-row>
      <el-col :span="7">
        <el-form-item label="学年度" prop="years">
          <el-input-number
            v-model="ruleForm.years"
            class="mx-4"
            controls-position="right"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="7">
        <el-form-item label="请选择生效时间" prop="start_time">
          <!-- <el-date-picker
            v-model="ruleForm.start_time"
            type="date"
            placeholder="请选择生效时间"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
          /> -->

          <el-date-picker
            v-model="ruleForm.start_time"
            type="datetime"
            placeholder="请选择生效时间"
            format="YYYY/MM/DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="7">
        <el-form-item label="请选择结束时间" prop="end_time">
          <!-- <el-date-picker
            v-model="ruleForm.end_time"
            type="date"
            placeholder="请选择结束时间"
            format="YYYY/MM/DD hh:mm"
            value-format="YYYY-MM-DD hh:mm"
          /> -->

          <el-date-picker
            v-model="ruleForm.end_time"
            type="datetime"
            placeholder="请选择结束时间"
            format="YYYY/MM/DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="7">
        <el-form-item label="缴费方式" prop="pay_type">
          <el-radio-group v-model="ruleForm.pay_type">
            <el-radio :label="0"> 光大银行 </el-radio>
            <el-radio :label="1"> 微信商户 </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="收费明细"> </el-form-item>

    <el-row v-for="(tollItem, index) in ruleForm.detail" :key="index">
      <el-col :span="8">
        <el-form-item
          label="缴费项目"
          :prop="'detail.' + index + '.charge_items'"
          :rules="{
            required: true,
            message: '请选择缴费项目',
            trigger: 'blur'
          }"
        >
          <el-select
            v-model="tollItem.charge_items"
            placeholder="缴费项目"
            style="width: 100%"
          >
            <el-option
              v-for="item in paymentItemArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item
          label="收费金额"
          :prop="'detail.' + index + '.expenses'"
          :rules="{
            required: true,
            message: '请输入收费金额',
            trigger: 'blur'
          }"
        >
          <el-input v-model="tollItem.expenses">
            <template #append>元</template>
          </el-input>
        </el-form-item>
      </el-col>

      <el-col :span="2" :push="2">
        <el-button
          v-if="ruleForm.detail.length !== 1"
          type="danger"
          :icon="Delete"
          circle
          @click="deleteChannelBtn(index)"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="2" :offset="10">
        <el-button type="primary" @click="newChannelFn">新增收费</el-button>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)"
        >添加</el-button
      >
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref } from 'vue'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { Delete } from '@element-plus/icons-vue'
import { paymentItemAPI, addPaymentSettingsAPI } from '@/api/payFees'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
const router = useRouter()
const ruleFormRef = ref(null)
const ruleForm = ref({
  name: '',
  level_id: '',
  years: 3,
  start_time: '',
  end_time: '',
  detail: [
    {
      charge_items: '', // 缴费项目
      expenses: '' // 金额
    }
  ]
})

const rules = {
  name: [
    {
      required: true,
      message: '必须输入缴费名称',
      trigger: 'blur'
    }
  ],
  level_id: [
    {
      required: true,
      message: '必须选择缴费层级',
      trigger: 'blur'
    }
  ],
  years: [
    {
      required: true,
      message: '必须选择学年度',
      trigger: 'blur'
    }
  ],
  start_time: [
    {
      required: true,
      message: '必须选择输入开始时间',
      trigger: 'blur'
    }
  ],
  end_time: [
    {
      required: true,
      message: '必须选择输入开始时间',
      trigger: 'blur'
    }
  ],
  pay_type: [
    {
      required: true,
      message: '请选择缴费渠道',
      trigger: 'blur'
    }
  ]
}

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()

// 缴费项目
const paymentItemArr = ref([])
const onPaymentItem = async () => {
  const data = await paymentItemAPI()
  paymentItemArr.value = data
}
onPaymentItem()

// 新增
const newChannelFn = () => {
  ruleForm.value.detail.push({
    charge_items: '', // 缴费项目
    expenses: '' // 金额
  })
}
// 删除
const deleteChannelBtn = (index) => {
  ruleForm.value.detail.splice(index, 1)
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      ruleForm.value.detail.forEach((item, index) => {
        const trmpItem = paymentItemArr.value.find(
          (findItem) => findItem.id === item.charge_items
        )
        ruleForm.value.detail[index].number = trmpItem.number
        ruleForm.value.detail[index].payment_name = trmpItem.name
      })
      console.log(JSON.stringify(ruleForm.value.detail))

      const tempObj = { ...ruleForm.value }
      tempObj.detail = JSON.stringify(tempObj.detail)

      // ruleForm.value.detail = JSON.stringify(ruleForm.value.detail)
      await addPaymentSettingsAPI(tempObj)
      ElMessage.success('添加缴费完成。')

      setTimeout(() => router.go(-1), 2000)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}
</script>

<style></style>
